<template>
  <sign-page
    title-text="辖区业务指导记录"
    :request="request"
    table-size="large"
    :column-list="columnList"
    :form-parms-add="formParmsAdd"
    :form-parms-update="formParmsUpdate"
    :table-actions-fixed="true"
    :init-add-form-data="initAddFormData"
    :tableActionsWidth="100"
    :need-data-file="true">
  </sign-page>
</template>

<script>
import {
  activityRequest as request
} from '@/api'

export default {
  computed: {
    formParmsAdd: {
      get () {
        let data = [{
          type: 'input',
          label: '医疗卫生机构名称',
          key: 'receiveOrgName'
        },
        {
          type: 'input',
          label: '指导形式',
          key: 'devMethod'
        },
        {
          type: 'input',
          label: '参与人数',
          key: 'receiveTotal',
          check: {
            rules: {
              type: 'number'
            }
          }
        },
        {
          type: 'textarea',
          label: '地点',
          key: 'address'
        },
        {
          type: 'datetimePicker',
          label: '开始时间',
          key: 'startDate'
        },
        {
          type: 'datetimePicker',
          label: '结束时间',
          key: 'endDate'
        }]
        return data
      }
    },
    formParmsUpdate: {
      get () {
        let data = []
        this.formParmsAdd.forEach((item) => {
          let newItem = {}
          for (let key in item) {
            newItem[key] = item[key]
          }
          data.push(newItem)
        })
        return data
      }
    },
    columnList: {
      get () {
        let data = []
        this.formParmsAdd.forEach((item) => {
          data.push({
            title: item.label,
            sort: true,
            field: item.key
          })
        })
        return data
      }
    }
  },
  data () {
    return {
      request: Object.assign({}, request, {
        get: this.loadData
      })
    }
  },
  methods: {
    initAddFormData () {
      return {type: 'bus'}
    },
    async loadData () {
      let parm = {
        type: 'bus'
      }
      return await request.get(parm) 
    }
  }
}
</script>
